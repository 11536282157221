/*! npm.im/object-fit-images 3.2.4 */
var objectFitImages = function () {
    "use strict";

    function t(t, e) {
        return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + t + "' height='" + e + "'%3E%3C/svg%3E"
    }

    function e(t) {
        if (t.srcset && !p && window.picturefill) {
            var e = window.picturefill._;
            t[e.ns] && t[e.ns].evaled || e.fillImg(t, {reselect: !0}), t[e.ns].curSrc || (t[e.ns].supported = !1, e.fillImg(t, {reselect: !0})), t.currentSrc = t[e.ns].curSrc || t.src
        }
    }

    function i(t) {
        for (var e, i = getComputedStyle(t).fontFamily, r = {}; null !== (e = u.exec(i));) r[e[1]] = e[2];
        return r
    }

    function r(e, i, r) {
        var n = t(i || 1, r || 0);
        b.call(e, "src") !== n && h.call(e, "src", n)
    }

    function n(t, e) {
        t.naturalWidth ? e(t) : setTimeout(n, 100, t, e)
    }

    function c(t) {
        var c = i(t), o = t[l];
        if (c["object-fit"] = c["object-fit"] || "fill", !o.img) {
            if ("fill" === c["object-fit"]) return;
            if (!o.skipTest && f && !c["object-position"]) return
        }
        if (!o.img) {
            o.img = new Image(t.width, t.height), o.img.srcset = b.call(t, "data-ofi-srcset") || t.srcset, o.img.src = b.call(t, "data-ofi-src") || t.src, h.call(t, "data-ofi-src", t.src), t.srcset && h.call(t, "data-ofi-srcset", t.srcset), r(t, t.naturalWidth || t.width, t.naturalHeight || t.height), t.srcset && (t.srcset = "");
            try {
                s(t)
            } catch (t) {
                window.console && console.warn("https://bit.ly/ofi-old-browser")
            }
        }
        e(o.img), t.style.backgroundImage = 'url("' + (o.img.currentSrc || o.img.src).replace(/"/g, '\\"') + '")', t.style.backgroundPosition = c["object-position"] || "center", t.style.backgroundRepeat = "no-repeat", t.style.backgroundOrigin = "content-box", /scale-down/.test(c["object-fit"]) ? n(o.img, function () {
            o.img.naturalWidth > t.width || o.img.naturalHeight > t.height ? t.style.backgroundSize = "contain" : t.style.backgroundSize = "auto"
        }) : t.style.backgroundSize = c["object-fit"].replace("none", "auto").replace("fill", "100% 100%"), n(o.img, function (e) {
            r(t, e.naturalWidth, e.naturalHeight)
        })
    }

    function s(t) {
        var e = {
            get: function (e) {
                return t[l].img[e ? e : "src"]
            }, set: function (e, i) {
                return t[l].img[i ? i : "src"] = e, h.call(t, "data-ofi-" + i, e), c(t), e
            }
        };
        Object.defineProperty(t, "src", e), Object.defineProperty(t, "currentSrc", {
            get: function () {
                return e.get("currentSrc")
            }
        }), Object.defineProperty(t, "srcset", {
            get: function () {
                return e.get("srcset")
            }, set: function (t) {
                return e.set(t, "srcset")
            }
        })
    }

    function o() {
        function t(t, e) {
            return t[l] && t[l].img && ("src" === e || "srcset" === e) ? t[l].img : t
        }

        d || (HTMLImageElement.prototype.getAttribute = function (e) {
            return b.call(t(this, e), e)
        }, HTMLImageElement.prototype.setAttribute = function (e, i) {
            return h.call(t(this, e), e, String(i))
        })
    }

    function a(t, e) {
        var i = !y && !t;
        if (e = e || {}, t = t || "img", d && !e.skipTest || !m) return !1;
        "img" === t ? t = document.getElementsByTagName("img") : "string" == typeof t ? t = document.querySelectorAll(t) : "length" in t || (t = [t]);
        for (var r = 0; r < t.length; r++) t[r][l] = t[r][l] || {skipTest: e.skipTest}, c(t[r]);
        i && (document.body.addEventListener("load", function (t) {
            "IMG" === t.target.tagName && a(t.target, {skipTest: e.skipTest})
        }, !0), y = !0, t = "img"), e.watchMQ && window.addEventListener("resize", a.bind(null, t, {skipTest: e.skipTest}))
    }

    var l = "fregante:object-fit-images", u = /(object-fit|object-position)\s*:\s*([-.\w\s%]+)/g,
        g = "undefined" == typeof Image ? {style: {"object-position": 1}} : new Image, f = "object-fit" in g.style,
        d = "object-position" in g.style, m = "background-size" in g.style, p = "string" == typeof g.currentSrc,
        b = g.getAttribute, h = g.setAttribute, y = !1;
    return a.supportsObjectFit = f, a.supportsObjectPosition = d, o(), a
}();

(function () {
    const $nav = document.querySelector('.navigation');
    const $body = document.querySelector('body');
    const $navtrigger = document.getElementById('navtrigger');
    $navtrigger.addEventListener('click', event => {
        console.log('nav trigger');
        $navtrigger.classList.toggle("close");
        $body.classList.toggle("fixed");
        $nav.classList.toggle("active");
    });
    objectFitImages();

    var lazyloadImages;

    if ("IntersectionObserver" in window) {
        lazyloadImages = document.querySelectorAll(".lazy");
        console.log(lazyloadImages);
        var imageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var image = entry.target;
                    image.classList.remove("lazy");
                    imageObserver.unobserve(image);
                }
            });
        });

        lazyloadImages.forEach(function (image) {
            imageObserver.observe(image);
        });
    } else {
        var lazyloadThrottleTimeout;
        lazyloadImages = document.querySelectorAll(".lazy");

        function lazyload() {
            if (lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
            }

            lazyloadThrottleTimeout = setTimeout(function () {
                var scrollTop = window.pageYOffset;
                lazyloadImages.forEach(function (img) {
                    if (img.offsetTop < (window.innerHeight + scrollTop)) {
                        img.src = img.dataset.src;
                        img.classList.remove('lazy');
                    }
                });
                if (lazyloadImages.length == 0) {
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 20);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
    }

    function detectLanguageFromURL() {
        const pathSegments = window.location.pathname.split('/').filter(Boolean);
        if (pathSegments.length > 0) {
            const possibleLang = pathSegments[0];
            // Define your supported languages
            const supportedLanguages = ['en', 'de', 'fr']; // Add your languages here
            if (supportedLanguages.includes(possibleLang)) {
                return possibleLang;
            }
        }
        return 'default'; // Default language if not detected
    }

    const currentLanguage = detectLanguageFromURL();
    const intern = ['KI Mitarbeiter-Assistent', 'KI Rechnungsbot']
    const internEN = ['AI Employee Assistant', 'AI Invoice Bot']

    const columnInternalTitle = currentLanguage === "en" ? 'Internal' : 'Intern'
    const columnExternalTitle = currentLanguage === "en" ? 'External' : 'Extern'
    const checkIntern = currentLanguage === "en" ? internEN : intern

    const menus = document.querySelectorAll('.js-two-column-menu');


    menus.forEach(menu => {
        const items = Array.from(menu.children); // Get all li elements

        // Initialize arrays for two columns based on title condition
        const column1 = [];
        const column2 = [];
        const h4_1 = document.createElement('h4');
        const h4_2 = document.createElement('h4');
        h4_1.classList.add('column-title');
        h4_2.classList.add('column-title');
        h4_1.textContent = columnInternalTitle;  // Customize the title as needed
        h4_2.textContent = columnExternalTitle;
        column1.push(h4_1)
        column2.push(h4_2)
        // Loop through each item to decide its column based on the title of the <a> tag
        items.forEach(item => {
            const anchor = item.querySelector('a'); // Find the <a> tag within the <li>
            if (anchor && anchor.title) { // Ensure the <a> tag exists and has a title attribute
                if (checkIntern.includes(anchor.title)) { // Condition for column 1
                    column1.push(item);
                } else { // Condition for column 2
                    column2.push(item);
                }
            }
        });


        // Create new UL elements for each column
        const ul1 = document.createElement('ul');
        const ul2 = document.createElement('ul');
        ul1.classList.add('menu-column');
        ul2.classList.add('menu-column');


        // Append items to new columns
        column1.forEach(item => ul1.appendChild(item));
        column2.forEach(item => ul2.appendChild(item));

        // Clear existing menu and append new columns
        menu.innerHTML = '';
        menu.appendChild(ul1);
        menu.appendChild(ul2);
    });
    // const children = document.querySelectorAll('.js-two-column-menu');
    //
    // // Loop through all child elements to attach hover behavior to the parent
    // children.forEach(child => {
    //     // Get the parent element you want to manipulate
    //     const parentElement = child.closest('.mainnav__listitem'); // Replace 'parent-class' with the actual class of the parent
    //
    //     // Define what should happen on hover
    //     const onHover = () => {
    //         // Manipulate the parent element's text or other properties
    //         parentElement.textContent = 'New text when hovered';
    //     };
    //
    //     // Define what should happen when hover ends
    //     const onHoverOut = () => {
    //         // Restore the original state of the parent element if needed
    //         parentElement.textContent = 'Original text';
    //     };
    //
    //     // Attach the hover event listeners to the parent
    //     parentElement.addEventListener('mouseenter', onHover);
    //     parentElement.addEventListener('mouseleave', onHoverOut);
    // });

    function positionHighlight(svgContainer, pathElement, highlightElement, name) {
        const boxRect = pathElement.getBoundingClientRect();
        const highlightElementRect = highlightElement.getBoundingClientRect();
        const path = pathElement.getBBox();

        let offsetX = path.x - 10;
        let offsetY = path.y - highlightElementRect.height + boxRect.height;
        if (name === "cost") {
            offsetX = path.x - 5;
            offsetY = path.y - highlightElementRect.height / 3;
        }

        highlightElement.style.position = "absolute";
        highlightElement.style.top = `${offsetY}px`;
        highlightElement.style.left = `${offsetX}px`;
    }

    const svgContainer = document.getElementById("svg-container");


    const datePath = document.getElementById("date");
    const dateHighlight = document.getElementById("date-highlight");
    positionHighlight(svgContainer, datePath, dateHighlight, "date");


    const namePath = document.getElementById("name");
    const nameHighlight = document.getElementById("name-highlight");
    positionHighlight(svgContainer, namePath, nameHighlight, "name");


    const costPath = document.getElementById("cost");
    const costHighlight = document.getElementById("cost-highlight");
    positionHighlight(svgContainer, costPath, costHighlight, "cost");

})();
